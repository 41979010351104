/* .image-container {
    width: 400px; 
    height: 300px;
    overflow: hidden;
    position: relative;
    border: 1px solid #ddd;
  }
  
  .zoom-image {
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;
  }
   */
   .image-container {
    width: 512px;
    height: 512px;
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    cursor: none;
  }
  
  .main-image {
    width: 100%;
    height: 100%;
  }
  
  .zoom-lens {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    border: 2px solid rgba(0, 0, 0, 0.5);
    background-size: 1000% 1000%; 
    background-repeat: no-repeat;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  